import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Legal } from '../../layouts/Legal';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Legal title="GDPR Privacy Policy">{children}</Legal>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Tier 1`}</h2>
    <h3>{`1. Introduction`}</h3>
    <p>{`The Board of Directors and management of Five Nines Digitals Ltd. are committed to compliance with all relevant EU and Member State laws in respect of personal data, and the protection of the "rights and freedoms" or individuals.`}</p>
    <p>{`Five Nines Digitals Ltd. collects and processes personal information in accordance with The General Data Protection Regulation (EU) 2016/679, which replaces the current EU Data Protection Directive of 1995 and UK Data Protection Act of 1998 and supersedes all previous laws and instructions.`}</p>
    <p>{`Document links within this document are available only to Five Nines Digital Ltd. Staff and agreed third parties.`}</p>
    <h3>{`2. Scope`}</h3>
    <p><strong parentName="p">{`Data`}</strong></p>
    <p>{`This policy applies to all Data held or processed by Five Nines Digital Ltd., for customers', clients', employees, suppliers' and partners'. Whether managed on premise or remotely via client connections.`}</p>
    <p>{`This includes personal data, the organisation processes from any source, and held by the company in either electronic or paper format that has been classified as Confidential or Restricted (Five Nines Digital Ltd. Classification Policy), particularly Personally Identifiable Information held or processed by Five Nines Digital Ltd. on any of the following (but not limited to):`}</p>
    <ul>
      <li parentName="ul">{`Desktops`}</li>
      <li parentName="ul">{`Laptops`}</li>
      <li parentName="ul">{`Phones`}</li>
      <li parentName="ul">{`Tablets`}</li>
      <li parentName="ul">{`Email`}</li>
      <li parentName="ul">{`External Media`}</li>
      <li parentName="ul">{`Websites`}</li>
      <li parentName="ul">{`Networks`}</li>
      <li parentName="ul">{`Hosting infrastructure`}</li>
      <li parentName="ul">{`Hard copy media`}</li>
    </ul>
    <p><strong parentName="p">{`Third Parties`}</strong></p>
    <p>{`Partners and third parties working with, or for Five Nines Digital Ltd. and who have, or may have access to personal data, will be expected to have read, understood and to comply with this policy (see section on Third Party / Suppliers).`}</p>
    <p><strong parentName="p">{`Geographical Jurisdiction`}</strong></p>
    <p>{`The GDPR applies to all controllers and processors that are established in the EU (European Union). It will also apply to controllers outside of the EU that process personal data in order to offer goods and services, or monitor the behaviour of data subjects who are resident in the EU.`}</p>
    <p>{`In respect of Five Nines Digital Ltd - this applies to all offices in all jurisdictions.`}</p>
    <h3>{`3. Definitions`}</h3>
    <p><strong parentName="p">{`Personal Information / PII`}</strong></p>
    <p>{`Personal Information or Personally Identifiable information is any information related to a natural person or 'Data Subject' that can be used to "directly or indirectly identify" a person. It can be anything from a name, a photo, an email address (personal or business), bank details, posts on social networking sites, medical information, or a computer IP address. See Annex B for more information related to PII.`}</p>
    <p><strong parentName="p">{`Special Categories of Personal Data`}</strong></p>
    <p>{`Personal data revealing any of the following categories cannot be processed unless the conditions listed below are met;`}</p>
    <ul>
      <li parentName="ul">{`The racial or ethnic origin of the data subject,`}</li>
      <li parentName="ul">{`Religious beliefs or other philosophical beliefs of a similar nature,`}</li>
      <li parentName="ul">{`Trade Union (within the meaning of the Trade Union and Labour Relations (Consolidation) Act 1992),`}</li>
      <li parentName="ul">{`Physical or mental health or condition,`}</li>
      <li parentName="ul">{`Genetic or biometric data`}</li>
      <li parentName="ul">{`Sexual orientation or data concerning a natural person's sex life`}</li>
      <li parentName="ul">{`There are also separate safeguards for alleged or criminal convictions`}</li>
      <li parentName="ul">{`The data subject has given explicit consent;`}</li>
      <li parentName="ul">{`It is necessary to fulfil the obligations of controller or of data subjects;`}</li>
      <li parentName="ul">{`It is necessary to protect the vital interests of the data subject;`}</li>
      <li parentName="ul">{`Processing is carried out by a foundation or not-for-profit organisation (only those in blue text)`}</li>
      <li parentName="ul">{`The personal data has manifestly been made public by the data subject (press leaks excluded)`}</li>
      <li parentName="ul">{`Establishment, exercise or defence of legal claims;`}</li>
      <li parentName="ul">{`Reasons of public interest in the area of public health; (NHS for instance)`}</li>
      <li parentName="ul">{`Archiving purposes in the public interest; (Census)`}</li>
      <li parentName="ul">{`A Member State has varied the definition of a special category. (if UK excludes anything)`}</li>
    </ul>
    <p><strong parentName="p">{`Data Subject`}</strong></p>
    <p>{`Any living individual who is the subject of personal data held by an organisation.`}</p>
    <p><strong parentName="p">{`Data Controller / Controller / Co-Controller`}</strong></p>
    <p>{`A person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which the personal data are, or are to be processed.`}</p>
    <p><strong parentName="p">{`Data Processor / Processor`}</strong></p>
    <p>{`A natural or legal person, public authority, agency or other body, who processes personal data on behalf of the controller`}</p>
    <p>{`(`}<strong parentName="p">{`Five Nines Digital Ltd. is a data processor`}</strong>{` when collecting data on behalf of Clients, including SaaS and Support).`}</p>
    <p><strong parentName="p">{`Processing`}</strong></p>
    <p>{`Any operation or set of operations which is performed on personal data or on sets of personal datas, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaption or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.`}</p>
    <p><strong parentName="p">{`Personal Data Breach`}</strong></p>
    <p>{`A breach of security leading to the accidental, or unlawful, destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored or otherwise processed.`}</p>
    <p><strong parentName="p">{`Third Party`}</strong></p>
    <p>{`A natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.`}</p>
    <p><strong parentName="p">{`Filing System`}</strong></p>
    <p>{`Any structured or set of personal data, which are accessible according to specific criteria, whether, centralised, decentralised or dispersed on a functional or geographical basis.`}</p>
    <p><strong parentName="p">{`Pseudonymisation`}</strong></p>
    <p>{`The technique of processing personal data in such a way that it can no longer be attributed to specific "data subject" without the use of additional information, which must be kept separately and be subject to technical and organisational measures to ensure non-attribution.`}</p>
    <p><strong parentName="p">{`Policy`}</strong></p>
    <p>{`Compliance with the GDPR is described by this and other relevant policies listed in Annex A and relates to all connected processes. Any breach of this Policy will be dealt with under Five Nines Digital Ltd.'s incident management process, must be reported to the Supervising Authority (where it involves Personal Information and presents a risk to the rights and freedoms of an individual). It may invoke the disciplinary policy and if deemed a criminal offence, will be reported as to the appropriate authorities.`}</p>
    <h3>{`4. Roles & Responsibilities`}</h3>
    <p><strong parentName="p">{`People`}</strong></p>
    <p>{`This Policy applies to all permanent, temporary or contract staff, third party suppliers or affiliates and visitors to Five Nines Digital Ltd. premises.`}</p>
    <p>{`The `}<strong parentName="p">{`GDPR Officer`}</strong>{` will be responsible for;`}</p>
    <ul>
      <li parentName="ul">{`Development and implementation of the GDPR as required by this policy`}</li>
      <li parentName="ul">{`Security and risk management in relation to compliance with our Risk Assessment Process & Methodology Document`}</li>
      <li parentName="ul">{`Ensuring that Five Nines Digital Ltd. complies with the GDPR`}</li>
      <li parentName="ul">{`Being the first point of call for Employees/Staff seeking clarification on any aspect of data protection compliance.`}</li>
    </ul>
    <p>{`The GDPR Officer will also review the retention dates of all personal data processed by Five Nines Digital Ltd., by reference to the data inventory, and will identify any data that is no longer required in the context of the registered purpose.`}</p>
    <h3>{`5. Consent`}</h3>
    <p>{`Five Nines Digital Ltd. understands 'consent' to mean that it has been explicitly and freely given, and a specific, informed and unambiguous indication of the data subject's wishes that, by statement or by a clear affirmative action, signifies agreement to the processing of personal datas relating to him or her. The data subject can withdraw their consent at any time.`}</p>
    <p>{`Five Nines Digital Ltd. understands 'consent' to mean that the data subject has been fully informed of the intended processing and has signified their agreement.`}</p>
    <p>{`Consent will not be inferred from non-response to a communication.`}</p>
    <p>{`For sensitive data, explicit written consent must be obtained, unless an alternative legitimate basis for processing exists.`}</p>
    <p>{`The GDPR permits certain disclosures without consent so long as the information is requested for one or more of the following purposes:`}</p>
    <ul>
      <li parentName="ul">{`To safeguard national security;`}</li>
      <li parentName="ul">{`Prevention or detection of crime including the apprehension or prosecution of offenders;`}</li>
      <li parentName="ul">{`Assessment or collection of tax duty;`}</li>
      <li parentName="ul">{`Discharge of regulatory functions (includes health, safety and welfare of persons at work);`}</li>
      <li parentName="ul">{`To prevent serious harm to a third party; and to protect the vital interests of the individual, this refers to life and death situations`}</li>
    </ul>
    <h3>{`6. The Principles`}</h3>
    <table>
      <thead>
        <tr>
    <th colSpan={3} style={{
            'text-align': 'left'
          }}>
    Data shall be;
    </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td width='10%'>1</td>
          <td width='45%'> Processed lawfully, fairly and in a transparent manner</td>
          <td width='45%'></td>
        </tr>
        <tr>
          <td>2</td>
          <td>
    Collected for specified, explicit and legitimate purposes and not further
    processed in a manner that is incompatible with those purpose
          </td>
          <td>
    further processing for archiving purposes in the public interest, scientific
    or historical research purposes or statistical purposes shall not be
    considered to be incompatible with the initial purposes
          </td>
        </tr>
        <tr>
          <td>3 </td>
          <td>
    Shall be adequate, relevant and not excessive in relation to the purpose or
    purposes for which they are processed
          </td>
          <td></td>
        </tr>
        <tr>
          <td>4 </td>
          <td> Accurate and, where necessary, kept up to date </td>
          <td>
    every reasonable step must be taken to ensure that personal data that are
    inaccurate, having regard to the purposes for which they are processed, are
    erased or rectified without delay:
          </td>
        </tr>
        <tr>
          <td>5 </td>
          <td>
    Personal data shall not be kept for longer than is necessary for that
    purpose or those purposes for which it was originally collecte
          </td>
          <td>
    Personal data may be stored for longer periods insofar as the personal data
    will be processed solely for archiving purposes in the public interest,
    scientific or historical research purposes or statistical purposes subject
    to implementation of the appropriate technical and organisational measures.
    Data must be deleted in line with our Records Management Policy / Schedule.
    Where data retention that exceeds the retention periods defined in our
    Records Management Policy / Schedule, justification must be subject to
    written approval from our GDPR Officer and clearly identified and in line
    with the requirements legislation.
          </td>
        </tr>
        <tr>
          <td>6 </td>
          <td>
    Processed in a manner that ensures appropriate security of the personal dat
          </td>
          <td>
    including protection against unauthorised or unlawful processing and against
    accidental loss, destruction or damage, using appropriate technical or
    organisational measures incorporating "Privacy by Design / Privacy by
    Default" in line with out Secure Development Policy
          </td>
        </tr>
      </tbody>
    </table>
    <h3>{`7. Technical Measures`}</h3>
    <p>{`Can include but are not limited to;`}</p>
    <ul>
      <li parentName="ul">{`Password protection`}</li>
      <li parentName="ul">{`Acceptable Use`}</li>
      <li parentName="ul">{`Automatic locking of idle terminals`}</li>
      <li parentName="ul">{`Virus checking software and firewalls`}</li>
      <li parentName="ul">{`Role-based access rights`}</li>
      <li parentName="ul">{`Encryption of devices`}</li>
      <li parentName="ul">{`Security of local and wide area networks`}</li>
      <li parentName="ul">{`Privacy enhancing technologies such as pseudonymisation and anonymisation`}</li>
      <li parentName="ul">{`Identifying appropriate international security standards relevant to Five Nines Digital Ltd.`}</li>
      <li parentName="ul">{`All personal data should be accessible only to those who need to use it, and access may only be granted in line with our Five Nines Digital Ltd. Control Policy`}</li>
    </ul>
    <p><strong parentName="p">{`Non-Technical Measures`}</strong></p>
    <p>{`Applying appropriate training levels through Five Nines Digital Ltd. - All Employees/Staff will be provided with training to ensure that they understand Five Nines Digital Ltd.'s policy and the procedures it has put into place to implement that policy. This will take place within 1 week of joining and annually thereafter.`}</p>
    <ul>
      <li parentName="ul">{`Ensuring awareness programme in place`}</li>
      <li parentName="ul">{`Measures that consider the reliability of employees`}</li>
      <li parentName="ul">{`The inclusion of data protection in employment contracts;`}</li>
      <li parentName="ul">{`Identification of disciplinary action measures for data breaches;`}</li>
      <li parentName="ul">{`Monitoring of staff for compliance with relevant security standards;`}</li>
      <li parentName="ul">{`Physical access controls to electronic and paper records;`}</li>
      <li parentName="ul">{`Adoption of a clear desk policy;`}</li>
      <li parentName="ul">{`Storing of paper based data in lockable fire-proof cabinets;`}</li>
      <li parentName="ul">{`Implementing a BYOD and Mobile Policy for portable devices`}</li>
      <li parentName="ul">{`Restricting the use of employee's own personal devices being used in the workplace using and MDM solution`}</li>
      <li parentName="ul">{`Making regular, secure backups of personal data and storing the media off collection site;`}</li>
      <li parentName="ul">{`The imposition of contractual obligations on the importing organisations to take appropriate security measures when the transferring data outside the EEA`}</li>
    </ul>
    <h3>{`8. The Rights of The Individual`}</h3>
    <p>{`Five Nines Digital Ltd. ensures that data subjects may exercise the following rights as per our Data Subject Rights Request Process`}</p>
    <table>
  <thead>
    <tr>
      <th width="50%" style={{
            "textAlign": "left"
          }}>
        The Right
      </th>
      <th width="50%"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        The Right to be Informed *(in the form of a privacy notice at the time
        the data is collected - or within 30 days if not collected directly)
      </td>
      <td> In the form of a Privacy Notice</td>
    </tr>
    <tr>
      <td>
        The Right of Access (Response without undue delay but provided within
        one month at no cost
      </td>
      <td>
        Information about how data is being process - Access to that data in a
        commonly used electronic format if requested - Any other supplementary
        information (as per privacy notice
      </td>
    </tr>
    <tr>
      <td>
        The Right to Rectification (Response without undue delay but provided
        within one month at no cost
      </td>
      <td> </td>
    </tr>
    <tr>
      <td>
        The Right to Erasure / Right to be Forgotten (Response without undue
        delay but there is no specific timeline mandated for completion (again
        no cost)
      </td>
      <td>
        Where the personal data is no longer necessary in relation to the
        purposes for which it was originally collected / processed - When the
        individual withdraws consent - When the individual objects to the
        processing and there is no overriding legitimate interest for continuing
        the processing. - The personal data was unlawfully processed (i.e.
        otherwise in breach of the GDPR). - The personal data must be erased in
        order to comply with a legal obligation
      </td>
    </tr>
    <tr>
      <td> Right to Restrict Processing </td>
      <td> </td>
    </tr>
    <tr>
      <td>
        The Right to Data Portability (Response without undue delay but provided
        within one month at no cost
      </td>
      <td>
        The right to data portability allows individuals to obtain and reuse
        their personal data for their own purposes across different services. It
        must be provided in a structured, commonly used and machine-readable
        form. Open formats include CSV files (this does not include PDF files).
        Note: if the personal data concerns more than one individual, you must
        consider whether providing the information would prejudice the rights of
        any other individua
      </td>
    </tr>
    <tr>
      <td> The Right to Object </td>
      <td> </td>
    </tr>
    <tr>
      <td> Rights in relation to automated decision making and profiling </td>
      <td>
        This is automated processing intended to evaluate certain personal
        aspects of an individual, in particular to analyse or predict
        <ul>
          <li>performance at work;</li>
          <li>economic situation;</li>
          <li>health;</li>
          <li>personal preferences;</li>
          <li>reListItemabiListItemty;</li>
          <li>behaviour;</li>
          <li>location; or</li>
          <li>movements.</li>
        </ul>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`When it has a legal or similarly significant effect on the individual. In this case, it is necessary to obtain human intervention, permit the data subject to express their point of view; and obtain an explanation of the decision and challenge it.`}</p>
    <p><strong parentName="p">{`Privacy Notice`}</strong></p>
    <p>{`Privacy notices must be issued at the time of Data collection or within 30 days if data is not obtained directly from the data subject.`}</p>
    <ul>
      <li parentName="ul">{`The identity and contact details of the controller and where applicable, the controller's representative and the data protection officer (Information Security Manager)`}</li>
      <li parentName="ul">{`Purpose of the processing and the legal basis for the processing`}</li>
      <li parentName="ul">{`The legitimate interests of the controller or third party, where applicable`}</li>
      <li parentName="ul">{`Categories of personal data (where not obtained directly from the data subject)`}</li>
      <li parentName="ul">{`Who the data was obtained from (where not obtained directly from the data subject)`}</li>
      <li parentName="ul">{`Any recipient or categories of recipients of the personal data and safeguards`}</li>
      <li parentName="ul">{`Details of transfers to third country and safeguards`}</li>
      <li parentName="ul">{`Retention period or criteria used to determine the retention period`}</li>
      <li parentName="ul">{`Description of technical and organisational security measures`}</li>
      <li parentName="ul">{`The Rights`}</li>
      <li parentName="ul">{`The Principles`}</li>
      <li parentName="ul">{`How to complain to the company and supervisory authority`}</li>
      <li parentName="ul">{`How to withdraw consent`}</li>
    </ul>
    <h3>{`9. Data Inventory`}</h3>
    <p>{`Five Nines Digital Ltd. has established a data inventory and data flow process as part of it approach to address risks and opportunities throughout its GDPR compliance project. Five Nines Digital Ltd.'s data inventory flow determines;`}</p>
    <ul>
      <li parentName="ul">{`Business processes that use personal data;`}</li>
      <li parentName="ul">{`Source of personal data;`}</li>
      <li parentName="ul">{`Volume of data subjects;`}</li>
      <li parentName="ul">{`Description of each item of personal data;`}</li>
      <li parentName="ul">{`Processing activity;`}</li>
      <li parentName="ul">{`Maintains the inventory of data categories of personal data processed;`}</li>
      <li parentName="ul">{`Documents the purpose(s) for which each category of personal data is used;`}</li>
      <li parentName="ul">{`Recipients, and potential, of the personal data;`}</li>
      <li parentName="ul">{`The role of Five Nines Digital Ltd. throughout the data flow;`}</li>
      <li parentName="ul">{`Key systems and repositories;`}</li>
      <li parentName="ul">{`Any data transfers; and`}</li>
      <li parentName="ul">{`All retention and disposal requirements.`}</li>
    </ul>
    <h3>{`10. Breach Reporting`}</h3>
    <p>{`All employees/staff, contractors or temporary personnel are responsible for reporting any and all personal data breaches (including those that appear to be insignificant) to the Information Security Manager in line with our Incident Management Policy & Process and using the Incident Report.`}</p>
    <ul>
      <li parentName="ul">{`A Processor must report a breach to the Controller without undue delay and no later than 72 hours after becoming aware of it`}</li>
      <li parentName="ul">{`The Controller must report it to the supervisory authority, no later than 72 hours after having become aware of it (where the breach is likely to adversely affect the personal data or privacy of the data subject.)`}</li>
    </ul>
    <h3>{`11. Third Party / Suppliers`}</h3>
    {
      /* No third parties or suppliers may be engaged without a Supplier Assessment being undertaken. */
    }
    <p>{`Five Nines Digital Ltd. must ensure that personal data is not disclosed to unauthorised third parties. All employees/staff should exercise caution when asked to disclose personal data held on another individual to a third party and if unsure, should refer to the Information Security Manager.`}</p>
    <p>{`Partners and any third parties working with or for Five Nines Digital Ltd. and who have access to personal data will be expected to have read, understood and to comply with this policy.`}</p>
    <p>{`No third party may access personal data held by Five Nines Digital Ltd. without having first entered into a data confidentiality agreement in line with our Supplier Relationships document, which imposes on the third party, obligations no less onerous than those to which Five Nines Digital Ltd. is committed, an which gives Five Nines Digital Ltd. the right to audit compliance with the agreement.`}</p>
    <p>{`Data cannot be shared with any third parties or change of third parties without disclosure to the Data Subject.`}</p>
    <p>{`Data cannot be shared with any third parties or change of third parties without prior intention communicated to the Data Controller where applicable.`}</p>
    <h3>{`12. Retention and Disposal`}</h3>
    <p>{`Five Nines Digital Ltd. shall not keep personal data in a form that permits identification of a data subjects for longer a period than is necessary, in relation to the purpose(s) for which the data was originally collected.`}</p>
    <p>{`Five Nines Digital Ltd.'s data retention and data disposal procedures in line with our Records Management Policy/Schedule will apply in all classes.`}</p>
    <p>{`All data received or databases created, processed, stored or received for clients must be recorded on the data register (data recording process).`}</p>
    <p>{`Personal data must be disposed of securely and must be done in accordance with the secure disposal procedure included in our Information Classification Policy.`}</p>
    <h3>{`13. International Data Transfers`}</h3>
    <p>{`All exports of data from within the European Economic Area (EEA) to non-European Economic Area countries (referred to in the GDPR as 'third countries') are unlawful unless there is an appropriate "level of protection for the fundamental rights of the data subjects".`}</p>
    <p>{`The EEA countries are currently the EU countries plus Iceland, Liechtenstein and Norway:`}</p>
    <p>{`Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Iceland, Ireland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, & United Kingdom.`}</p>
    <p><strong parentName="p">{`Agreed Countries with adequacy decision`}</strong></p>
    <p>{`Andorra, Argentina, Faroe Islands, Guernsey, Isle of Man, Israel, Jersey, New Zealand, Switzerland, Uruguay`}</p>
    <p><strong parentName="p">{`Privacy Shield`}</strong></p>
    <p>{`If there is a requirement to transfer or store personal data from the EU to an organisation in the United States, the receiving organisation must be signed up to the Privacy Shield framework at the U.S. Department of Commerce. A statement of adherence to the framework is not sufficient - they must be registered.`}</p>
    <p>{`Binding corporate rules & Model Contract Clauses`}</p>
    <p>{`Five Nines Digital Ltd. may adopt approved binding corporate rules or pre-approved model contract clauses for the transfer of data outside the EU with other organisations. This requires submission to the relevant supervisory authority (Information Commissioners Office) for approval of the rules that Five Nines Digital Ltd. is seeking to rely upon.`}</p>
    <p>{`(refer to International Transfers Document for detailed information).`}</p>
    <h3>{`14. Exceptions`}</h3>
    <p>{`In the absence of any of the above transfers can still be made under the following conditions;`}</p>
    <ul>
      <li parentName="ul">{`The data subject has explicitly consented to the proposed transfer (but must be informed of any possible risks before making that decision)`}</li>
      <li parentName="ul">{`The transfer is necessary for the performance of a contract between the data subject and the controller or for the implementation of pre-contractual measures.`}</li>
      <li parentName="ul">{`The transfer is necessary for the conclusion or performance of a contract concluded in the interest of the data subject between the controller and another natural or legal person`}</li>
      <li parentName="ul">{`The transfer is necessary for important reasons of public interest`}</li>
      <li parentName="ul">{`The transfer is necessary for the establishment, exercise or defence of legal claims; and/or`}</li>
      <li parentName="ul">{`The transfer is necessary in order to protect the vital interests of the data subject or of other persons, where the data subject is physically or legally incapable of giving consent.`}</li>
    </ul>
    <p>{`None of these exceptions will negate the Right to be informed.`}</p>
    <h3>{`15. Annex A - Referenced Policies & Documents`}</h3>
    <p>{`Information Classification Policy`}</p>
    <p>{`Information Security Policy`}</p>
    <p>{`Risk assessment process & methodology`}</p>
    <p>{`Records Management`}</p>
    <p>{`Incident Management Policy & Process`}</p>
    <p>{`Incident Report`}</p>
    {
      /* Supplier Relationships */
    }
    <p>{`Secure Development Policy`}</p>
    <p>{`Control Policy`}</p>
    <p>{`GDPR_Form_Data Subject Rights Request Process`}</p>
    <p>{`GDPR_Form_Subject Access Request (SAR)`}</p>
    <p>{`GDPR_Form_Data Subject Rights Request`}</p>
    <p>{`GDPR_Form_Complaint`}</p>
    <h3>{`16. Annex B - PII`}</h3>
    <p>{`Note: this is not a definitive list. It is for guidance.`}</p>
    <table>
  <thead>
    <tr>
      <th width='50%' style={{
            "textAlign": "left"
          }}>Category of Information
      </th>
      <th width='50%' style={{
            "textAlign": "left"
          }}>Comments</th>
    </tr>
  </thead>
    <tbody>
        <tr>
  <td width="50%">A letter written in a person's official capacity </td>
  <td width="50%"></td>
        </tr>
        <tr>
  <td>Bank Details </td>
  <td></td>
        </tr>
        <tr>
  <td>Business Cards </td>
  <td></td>
        </tr>
        <tr>
  <td>Business Telephone Number </td>
  <td> Direct Line</td>
        </tr>
        <tr>
  <td>Bio-metric Data - Retina, face, fingertips, handwriting </td>
  <td> Sensitive Personal</td>
        </tr>
        <tr>
  <td>Car VIN Number or number plate (where registered to an individual) </td>
  <td></td>
        </tr>
        <tr>
  <td>Cookies </td>
  <td></td>
        </tr>
        <tr>
  <td>Credit Cards / Bank Cards / Store Cards </td>
  <td></td>
        </tr>
        <tr>
  <td>Credit Score / Record </td>
  <td></td>
        </tr>
        <tr>
  <td>Criminal Record </td>
  <td>
    carried out only under the control of official authority or when the
    processing is authorised by Union or Member State law providing for
    appropriate safeguards for the rights and freedoms of data subjects
  </td>
        </tr>
        <tr>
  <td>Date of Birth </td>
  <td></td>
        </tr>
        <tr>
  <td>
    Details about a person's land ownership or disputes to do with their land
  </td>
  <td></td>
        </tr>
        <tr>
  <td>Digital Identity </td>
  <td></td>
        </tr>
        <tr>
  <td>Disability Information </td>
  <td></td>
        </tr>
        <tr>
  <td>Driver's License Number </td>
  <td></td>
        </tr>
        <tr>
  <td>Education and employment history </td>
  <td></td>
        </tr>
        <tr>
  <td>Email address (personal and business) </td>
  <td></td>
        </tr>
        <tr>
  <td>Ethnicity / Race </td>
  <td></td>
        </tr>
        <tr>
  <td>Events attended </td>
  <td></td>
        </tr>
        <tr>
  <td>Fingerprints </td>
  <td></td>
        </tr>
        <tr>
  <td>Full Name </td>
  <td></td>
        </tr>
        <tr>
  <td>Gender </td>
  <td></td>
        </tr>
        <tr>
  <td>Genetic Information </td>
  <td> Sensitive Personal</td>
        </tr>
        <tr>
  <td>History / background </td>
  <td></td>
        </tr>
        <tr>
  <td>Home address </td>
  <td></td>
        </tr>
        <tr>
  <td>Insurance Details </td>
  <td></td>
        </tr>
        <tr>
  <td>IP Address </td>
  <td> (not PII by itself in USA)</td>
        </tr>
        <tr>
  <td>Job Position / title (with Company) </td>
  <td></td>
        </tr>
        <tr>
  <td>Location information </td>
  <td></td>
        </tr>
        <tr>
  <td>MAC address </td>
  <td></td>
        </tr>
        <tr>
  <td>Maiden Name </td>
  <td></td>
        </tr>
        <tr>
  <td>Medical / Health Information </td>
  <td></td>
        </tr>
        <tr>
  <td>Mother maiden name </td>
  <td></td>
        </tr>
        <tr>
  <td>National ID numbers / Social security number </td>
  <td></td>
        </tr>
        <tr>
  <td>Next of Kin information </td>
  <td></td>
        </tr>
        <tr>
  <td>NI Numbers </td>
  <td></td>
        </tr>
        <tr>
  <td>Opinions given as part of a person's employment </td>
  <td></td>
        </tr>
        <tr>
  <td>Organisation Memberships </td>
  <td></td>
        </tr>
        <tr>
  <td>Patient ID </td>
  <td></td>
        </tr>
        <tr>
  <td>Passport Number </td>
  <td></td>
        </tr>
        <tr>
  <td>Passwords </td>
  <td></td>
        </tr>
        <tr>
  <td>Photographic Passes (train / business) </td>
  <td></td>
        </tr>
        <tr>
  <td>Photos </td>
  <td></td>
        </tr>
        <tr>
  <td>Place of birth </td>
  <td> When used in conjunction with other information</td>
        </tr>
        <tr>
  <td>Political and religious leanings and affiliation </td>
  <td> Sensitive Personal</td>
        </tr>
        <tr>
  <td>Salary </td>
  <td></td>
        </tr>
        <tr>
  <td>Security Tokens </td>
  <td></td>
        </tr>
        <tr>
  <td>Session Information and tokens e.g. JSESSIONID</td>
  <td></td>
        </tr>
        <tr>
  <td>Sexual Orientation </td>
  <td> Sensitive Personal</td>
        </tr>
        <tr>
  <td>Status </td>
  <td></td>
        </tr>
        <tr>
  <td>Mobile Phone number or house phone </td>
  <td></td>
        </tr>
        <tr>
  <td>Trade Union Membership </td>
  <td> Sensitive Personal</td>
        </tr>
        <tr>
  <td>Usernames / Screen names / aliases </td>
  <td></td>
        </tr>
        <tr>
  <td>
    Vehicle Registration Plate Number (where vehicle registered to individual)
  </td>
  <td></td>
        </tr>
        <tr>
  <td>Video Recording </td>
  <td></td>
        </tr>
        <tr>
  <td>Views on controversial issues / Philosophical beliefs </td>
  <td> Sensitive Personal</td>
        </tr>
        <tr>
  <td>Visas </td>
  <td></td>
        </tr>
        <tr>
  <td>What are you doing when / status (Social Network Sites) </td>
  <td></td>
        </tr>
        <tr>
  <td>Where the information is so unique that it cannot be anyone else </td>
  <td></td>
        </tr>
      </tbody>
    </table>
    <h3>{`17. Document Owner and Approval`}</h3>
    <p>{`Our Co-Founder is the owner of this document and is responsible for ensuring that this policy document is reviewed annually as a minimum.`}</p>
    <p>{`A current version of this document is available to all members of our staff on our Platform.`}</p>
    <p>{`This policy was approved by our Co-Founder and is issued on a version-controlled basis under his signature.`}</p>
    <h3>{`Change History Record`}</h3>
    <table>
  <thead>
    <tr>
      <th width="10%" style={{
            "textAlign": "left"
          }}>
        Issue
      </th>
      <th width="50%" style={{
            "textAlign": "left"
          }}>
        Description of Change
      </th>
      <th width="20%" style={{
            "textAlign": "left"
          }}>
        Approval
      </th>
      <th width="20%" style={{
            "textAlign": "left"
          }}>
        Date of Issue
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td width="10%">1.0 </td>
      <td width="50%">Initial Issue </td>
      <td width="20%">Co-Founder </td>
      <td width="20%">06/04/2020</td>
    </tr>
  </tbody>
    </table>
    <p>{`This Document is the property of Five Nines Digital Ltd.
Classification - Public`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      